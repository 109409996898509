@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.feature {
  @include buttonSizes('38', '46', '52', '52', '52', '52');

  span[id='button-span-text'] {
    @include typography-s;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-base;
    }
  }
}
