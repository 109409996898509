@use '@queries' as *;
@use '@typography' as *;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  max-width: 142px;
  height: 42px;
  padding: 4.5px 12px;
  border: 2px solid var(--cst-inputauth-outline-default);
  border-radius: var(--cornerradius8);
  margin-bottom: 0;
  transition: 0.2s ease all;

  @include min-428-break {
    max-width: 194px;
    height: 52px;
    padding: 6.5px 14px;
  }

  @include min-744-break {
    max-width: 330px;
    height: 60px;
    padding: 7.5px 14px;
  }

  @include min-1024-break {
    max-width: 95px;
    height: 38px;
    padding: 2.5px 10px;
  }

  @include min-1440-break {
    max-width: 134px;
    height: 48px;
    padding: 4.5px 14px;
  }

  &:hover {
    border: 2px solid #fdfdfd;
    box-shadow: 0 0 0 4px #6941c633;
  }
  &:focus-within {
    border: 2px solid var(--cst-inputauth-outline-pressed);
    box-shadow: 0 0 0 4px #6941c633;
  }
}

.input-container {
  display: flex;
  flex-direction: row;
}

.value-input {
  width: 100%;
  outline: none;
  border: none;
  font-family: Inter, sans-serif;
  @include typography-s;

  @include min-428-break {
    @include typography-base;
  }

  @include min-744-break {
    @include typography-m;
  }

  @include min-1024-break {
    @include typography-s;
  }
  @include min-1440-break {
    @include typography-base;
  }
}

.label {
  @include typography-xs;
  color: var(--cst-inputauth-title-default);

  @include min-428-break {
    @include typography-s;
  }

  @include min-744-break {
    @include typography-base;
  }
  @include min-1024-break {
    @include typography-xs;
  }
  @include min-1440-break {
    @include typography-s;
  }
}

.currency {
  color: #303030;
  padding: 1px 0;
  @include typography-s;

  @include min-428-break {
    @include typography-base;
  }

  @include min-744-break {
    @include typography-m;
  }
  @include min-1024-break {
    @include typography-s;
  }
  @include min-1440-break {
    @include typography-base;
  }
}
