@use '@typography' as *;
@use '@queries' as *;
.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.title {
  @include typography-s;
  @include min-428-break {
    @include typography-m;
  }
  @include min-1024-break {
    @include typography-s;
  }
  @include min-1440-break {
    @include typography-base;
  }
}

.slider-wrapper {
  height: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  @include min-428-break {
    height: 23px;
  }
}

.inputs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.multi-range-slider * {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
.multi-range-slider {
  max-width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  width: 100%;
  flex-direction: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge,*/

  @include min-1024-break {
    max-width: 270px;
  }
}
.multi-range-slider .bar {
  display: flex;
}
.multi-range-slider .bar-left {
  width: 25%;
  background-color: var(--cst-sliders-line);
  opacity: 0.8;
  border-radius: 10px;
}
.multi-range-slider .bar-right {
  width: 25%;
  background-color: var(--cst-sliders-line);
  opacity: 0.8;
  border-radius: 10px;
}
.multi-range-slider .bar-inner {
  background-color: var(--cst-sliders-lineactive);
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  height: 2px;
  justify-content: space-between;
  position: relative;

  @include min-428-break {
    height: 2.5px;
  }

  @include min-1920-break {
    height: 3px;
  }
}
.multi-range-slider .bar-inner-left {
  width: 50%;
}
.multi-range-slider .bar-inner-right {
  width: 50%;
}
.multi-range-slider .thumb {
  background-color: white;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.multi-range-slider .thumb::before {
  content: '';
  background-color: inherit;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  z-index: 1;
  margin: -8px -5px;
  cursor: pointer;
  background: url('../../assets/slider/slider-circle.svg') center center;
  object-fit: contain;

  @include min-428-break {
    width: 23px;
    height: 23px;
    margin: -10.5px -12px;
  }

  @include min-1920-break {
    width: 24px;
    height: 24px;
    margin: -10.5px -12px;
  }
}
.multi-range-slider .input-type-range:focus + .thumb::after {
  content: '';
  position: absolute;
  top: -4px;
  left: -7px;
  width: 11px;
  height: 11px;
  z-index: 2;
  border-radius: 50%;
  border: dotted 1px black;
  /*box-shadow: 0px 0px 5px white, inset 0px 0px 10px black;*/
}
.multi-range-slider .caption {
  position: absolute;
  bottom: 35px;
  width: 2px;
  height: 2px;
  left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  display: none;
}
.multi-range-slider .thumb .caption * {
  position: absolute;
  min-width: 30px;
  height: 30px;
  font-size: 75%;
  font-family: Inter, sans-serif;
  text-align: center;
  line-height: 30px;
  background-color: #8f8f8f;
  border-radius: 50%;
  color: white;
  padding: 0px 5px;
  white-space: nowrap;
}
.multi-range-slider .thumb:active .caption {
  display: flex;
}
.multi-range-slider .input-type-range:focus + .thumb .caption {
  display: flex;
}
.multi-range-slider .input-type-range {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.multi-range-slider .labels {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: -20px;
  /* display: none; */
}
.multi-range-slider .label {
  font-size: 80%;
  display: flex;
  width: 1px;
  justify-content: center;
}
.multi-range-slider .label:first-child {
  justify-content: start;
}
.multi-range-slider .label:last-child {
  justify-content: end;
}
.multi-range-slider.zero-ranage-margin .thumb-left {
  right: 12px;
}
.multi-range-slider.zero-ranage-margin .thumb-right {
  left: 8px;
}

/* Disabled */
.multi-range-slider.disabled {
  background-color: var(--cst-sliders-line);
  opacity: 0.8;
}
.multi-range-slider .bar {
  display: flex;
}
.multi-range-slider.disabled .bar-left {
  background-color: var(--cst-sliders-line);
  opacity: 0.8;
}
.multi-range-slider.disabled .bar-right {
  background-color: var(--cst-sliders-line);
  opacity: 0.8;
}
.multi-range-slider.disabled .bar-inner {
  background-color: var(--cst-sliders-line);
}
.multi-range-slider.disabled .thumb {
}
.multi-range-slider.disabled .thumb::before {
}
.multi-range-slider.disabled .input-type-range:focus + .thumb::after {
}
.multi-range-slider.disabled .thumb .caption * {
}
