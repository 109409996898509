@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.slider {
  display: flex;
}

.slider.horizontal {
  flex-direction: column;
  width: 100%;
}

.slider.vertical {
  height: 150px;
}

.label-container {
  display: flex;
  justify-content: space-between;
}

.slider.horizontal .track {
  width: 100%;
}

.track,
.pseudo-track {
  height: 2px;
  border-radius: 10px;
  background-color: var(--cst-sliders-line);

  @include min-428-break {
    height: 2.5px;
  }

  @include min-1920-break {
    height: 3px;
  }
}

.track {
  :nth-child(2) {
    transform: translate(-21%, -50%) !important;
  }

  :nth-child(3) {
    transform: translate(-79%, -50%) !important;
  }
}

.pseudo-track {
  background-color: var(--cst-sliders-lineactive);
  opacity: 1;
}

/* track line */
.track:before {
  content: attr(x);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  position: absolute;

  border-radius: 10px;
}

/*
.slider.horizontal .track:before {
  height: 3px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
*/

.slider.vertical .track {
  width: 30px;
  height: 100%;
}

.slider.vertical .track:before {
  width: 3px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.thumb {
  @include singleFixedSize(18px);
  border-radius: 50%;
  cursor: pointer;
  background: url('../../assets/slider/slider-circle.svg') center center;
  object-fit: contain;

  @include min-428-break {
    @include singleFixedSize(23px);
  }

  @include min-1024-break {
    @include singleFixedSize(24px);
  }
}

.thumb.dragging {
}

.thumb.focus {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 2px var(--cst-sliders-line);
  }
  100% {
    box-shadow: 0 0 0 5px var(--cst-sliders-line);
  }
}

.slider.horizontal .thumb {
  top: 50%;
}

.slider.vertical .thumb {
  left: 50%;
}

.track.disabled {
  opacity: 0.4;
}
